import React, {useEffect, useState} from 'react'

import {Link} from 'react-router-dom'
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  IconButton,
  Divider,
  Badge,
  Box
} from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MUIMenuIcon from '@mui/icons-material/Menu'
import config from 'src/config'
import {useAuth} from 'src/context/Auth'
import {PageType} from 'src/entities/Page'
import MenuIcon from './MenuIcon'

interface MenuItemProps {
  text: string
  to: string
  type: PageType
  permitRoles?: string[]
  denyRoles?: string[]
}

const menuItems: MenuItemProps[] = [
  {
    text: 'My Tasks',
    to: '/mytasks',
    type: PageType.MyTasks,
    denyRoles: ['SalesRep', 'SalesRepAdmin']
  },
  {
    text: 'Projects',
    to: '/projectsTable',
    type: PageType.ProjectList
  },
  {
    text: 'Issues',
    to: '/adminIssue',
    type: PageType.adminIssue,
    denyRoles: ['SalesRep', 'SalesRepAdmin']
  },
  {
    text: 'All Tasks',
    to: '/items',
    type: PageType.ItemList,
    denyRoles: ['SalesRep', 'SalesRepAdmin']
  },
  {
    text: 'All Tasks V2',
    to: '/items/v2',
    type: PageType.ItemListV2,
    denyRoles: ['SalesRep', 'SalesRepAdmin']
  },
  {
    text: 'OCR Tracker',
    to: '/ocrtracker',
    type: PageType.OcrTracker,
    denyRoles: ['SalesRep', 'SalesRepAdmin']
  },
  {
    text: 'Documents',
    to: '/documents',
    type: PageType.Documents,
    denyRoles: ['SalesRep', 'SalesRepAdmin']
  },
  {
    text: 'Property Reports',
    to: '/PropertyReportsMenu',
    type: PageType.PropertyReport,
    denyRoles: ['SalesRep', 'SalesRepAdmin']
  },
  {
    text: 'DD Report',
    to: '/ddReport',
    type: PageType.ManagementReports,
    denyRoles: ['SalesRep', 'SalesRepAdmin']
  },
  {
    text: 'Scopes',
    to: '/scopes',
    type: PageType.Scopes,
    denyRoles: ['SalesRep', 'SalesRepAdmin']
  },
  {
    text: 'Management Reports',
    to: '/reports',
    type: PageType.ManagementReports,
    permitRoles: ['Admin', 'SalesRep', 'SalesRepAdmin']
  },
  {
    text: 'Invoices',
    to: '/invoices',
    type: PageType.Invoices,
    permitRoles: ['Admin', 'SalesRep', 'SalesRepAdmin']
  },
  {
    text: 'Clients',
    to: '/clients',
    type: PageType.Client,
    permitRoles: ['Admin']
  },
  {
    text: "What's New",
    to: '/whats-new',
    type: PageType.WhatsNew,
    denyRoles: ['SalesRep', 'SalesRepAdmin']
  }
]

function MenuListItem({
  to,
  text,
  icon
}: {
  to: string
  text: string
  icon: React.ReactNode
}) {
  return (
    <ListItem button component={Link} to={to}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  )
}

function MenuList({onClick}: {onClick: () => void}) {
  const [whatsNewCount, setWhatsNewCounts] = useState(0)
  const [adminIssueCount, setAdminIssueCount] = useState(0)
  const {roles, dbUser, fetchWithUser} = useAuth()

  useEffect(() => {
    fetchWithUser(config.apiUrl + '/changeLog/user/count')
      .then(response => response.json())
      .then(data => setWhatsNewCounts(data))
      .catch()

    fetchWithUser(config.apiUrl + '/adminIssue/user/count')
      .then(response => response.json())
      .then(data => setAdminIssueCount(data))
      .catch()
  }, [fetchWithUser])

  return (
    <Box width={250} role="presentation" onClick={onClick} onKeyDown={onClick}>
      <List>
        {menuItems.map((item, key) => {
          if (
            (!item.denyRoles ||
              !roles?.every(r => item.denyRoles?.includes(r))) &&
            (!item.permitRoles ||
              roles?.find(r => item.permitRoles?.includes(r))) &&
            (item.type !== PageType.ItemListV2 ||
              (item.type === PageType.ItemListV2 &&
                dbUser?.hasDuplicateItemPage))
          )
            return (
              <MenuListItem
                key={key}
                {...item}
                icon={
                  item.type === PageType.WhatsNew ||
                  item.type === PageType.adminIssue ? (
                    <Badge
                      badgeContent={
                        item.type === PageType.WhatsNew
                          ? whatsNewCount
                          : adminIssueCount
                      }
                      color="error"
                    >
                      <MenuIcon page={item.type}></MenuIcon>
                    </Badge>
                  ) : (
                    <MenuIcon page={item.type}></MenuIcon>
                  )
                }
              ></MenuListItem>
            )
          return <> </>
        })}
      </List>
    </Box>
  )
}

function MenuHeader({onClick}: {onClick: () => void}) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        paddingy: 1,
        justifyContent: 'flex-end'
      }}
    >
      <IconButton onClick={onClick} size="large">
        <ChevronLeftIcon />
      </IconButton>
    </Box>
  )
}

function MenuIconButton({onClick}: {onClick: () => void}) {
  return (
    <IconButton
      color="inherit"
      aria-label="open drawer"
      onClick={onClick}
      edge="start"
      size="large"
      sx={{marginRight: 2}}
    >
      <MUIMenuIcon />
    </IconButton>
  )
}

export default function Menu() {
  const [open, setOpen] = useState(false)

  const openDrawer = () => {
    setOpen(true)
  }

  const closeDrawer = () => {
    setOpen(false)
  }

  return (
    <>
      <MenuIconButton onClick={openDrawer} />
      <Drawer anchor={'left'} open={open} onClose={closeDrawer}>
        <MenuHeader onClick={closeDrawer} />
        <Divider />
        <MenuList onClick={closeDrawer} />
      </Drawer>
    </>
  )
}
