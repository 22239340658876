import React, {
  CSSProperties,
  ReactText,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import {styled} from '@mui/material/styles'
import classNames from 'classnames'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TablePagination,
  TableSortLabel,
  Checkbox,
  TablePaginationProps,
  tableCellClasses
} from '@mui/material'
import {
  tableSort,
  getComparator,
  OrderDirection
} from 'src/components/custom/table/CuiTableSort'
import CuiTableSearch from 'src/components/custom/table/CuiTableSearch'
import CuiTablePaginationActions from 'src/components/custom/table/CuiTablePaginationActions'
import CuiTableBody from 'src/components/custom/table/CuiTableBody'
import {getDescendantProp} from 'src/components/custom/table/CuiTableSort'

const PREFIX = 'CuiTable'
const PREFIXPaginig = 'CuiTablePaginig'

const classes = {
  table: `${PREFIX}-table`,
  denseUI: `${PREFIX}-denseUI`,
  windowsingCell: `${PREFIX}-windowsingCell`,
  windowsingExpandingCell: `${PREFIX}-windowsingExpandingCell`
}
const classesPaginig = {
  tablePagination: `${PREFIXPaginig}-tablePagination`
}

const StyledTableContainer = styled(TableContainer)(({theme}) => ({
  [`& .${classes.table}`]: {
    minWidth: 50,
    [`& .${tableCellClasses.stickyHeader}`]: {
      backgroundColor: theme.palette.grey['100']
    }
  },

  [`&.${classes.denseUI}`]: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(1)
    }
  },
  [`& .${classes.windowsingCell}`]: {
    flexGrow: 0,
    flexShrink: 0
  },
  [`& .${classes.windowsingExpandingCell}`]: {
    flex: 1
  }
}))
const StyledTablePagination = styled((props: TablePaginationProps) => {
  const p = {...props, component: 'div'}
  return <TablePagination {...p}></TablePagination>
})(() => ({
  [`& .${classesPaginig.tablePagination}`]: {
    borderBottomWidth: 0
  }
}))

const defaultRowPerPageOptions = [10, 25, 50, {label: 'All', value: -1}]

const columnDefaultValues = {
  isSort: true,
  type: 'text'
}

const fillColumnDefaultValues = (column: CuiColumn) => {
  Object.entries(columnDefaultValues).forEach(([key, val]) => {
    // @ts-ignore
    if (column[key] === undefined) column[key] = val
  })
  return column
}

export interface CuiColumn {
  id: string
  propertyKey?: string
  label?: string
  minWidth?: number
  width?: number | string
  align?: 'right' | 'center'
  format?: (value: any) => string
  isOrderByWithoutFormat?: boolean
  isSort?: boolean
  node?: (value: any) => React.ReactNode
}

export interface CuiRowProps {
  row: any
  columns: CuiColumn[]
  onRowClick?: (row: any) => void
  onRowCheck?: (row: any) => void
  onRowRender?: (row: any) => void
  onRowEdit?: (row: any) => void
  hover?: boolean
  isSelected?: boolean
  checkboxColumn?: boolean
  editColumn?: boolean
  isDisabled?: boolean
  isDisabledAction?: boolean
  clearSelectedRow?: boolean
  style?: CSSProperties
  windowingClasses?: boolean
  collapseRow?: (value: any) => React.ReactNode
}

interface CuiTableProps {
  columns: CuiColumn[]
  rows: any[]
  keyColumn?: string //in use for selected row indication
  selectedKeys?: ReactText | ReactText[]
  stickyHeader?: boolean
  maxHeight?: string | number
  orderDirection?: OrderDirection
  orderByColumn?: string
  hasPaging: boolean
  rowsPerPageOptions?: any[]
  defaultRowsPerPage?: number
  searchColumns?: string[]
  searchPlaceHolder?: string
  onChangeOrder?: (
    orderByColumn: string,
    orderDirection: OrderDirection
  ) => void
  onChangeRowsPerPage?: (rowsPerPage: number) => void
  denseUI?: boolean
  leavePositionOnRefresh?: boolean
  onRowClick?: (row: any) => void
  onRowCheck?: (checkedRowsKeys: ReactText[]) => void
  onRowEdit?: (row: any) => void
  hover?: boolean
  loading?: boolean
  checkboxColumn?: boolean
  editColumn?: boolean
  clearSelectedRow?: boolean
  selectAllCheckboxDisabled?: boolean
  disableRow?: (row: any) => boolean
  disableActionRow?: (row: any) => boolean
  onRowRender?: (row: any) => void
  windowing?: boolean
  collapseRow?: (value: any) => React.ReactNode
}

export default function CuiTable({
  columns,
  rows,
  keyColumn,
  selectedKeys,
  stickyHeader,
  maxHeight,
  orderDirection,
  orderByColumn,
  hasPaging,
  rowsPerPageOptions,
  defaultRowsPerPage,
  searchColumns,
  searchPlaceHolder,
  onChangeOrder,
  onChangeRowsPerPage,
  denseUI,
  leavePositionOnRefresh,
  onRowClick,
  onRowCheck,
  onRowEdit,
  hover,
  loading,
  checkboxColumn,
  editColumn,
  selectAllCheckboxDisabled,
  disableRow,
  disableActionRow,
  onRowRender,
  windowing,
  collapseRow,
  clearSelectedRow
}: CuiTableProps) {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(() => {
    if (hasPaging === false) return -1
    const options = rowsPerPageOptions || defaultRowPerPageOptions
    if (
      defaultRowsPerPage &&
      options.find(
        o => o === defaultRowsPerPage || o.value === defaultRowsPerPage
      )
    )
      return defaultRowsPerPage
    return options[0]
  })
  const [order, setOrder] = useState<OrderDirection>(orderDirection || 'asc')
  const [orderBy, setOrderBy] = useState(
    columns.find(c => c.id === orderByColumn)?.id || columns[0].id
  )
  const [searchValue, setSearchValue] = useState('')
  const selected = useMemo(() => {
    return Array.isArray(selectedKeys)
      ? selectedKeys
      : selectedKeys
      ? [selectedKeys]
      : []
  }, [selectedKeys])

  const onHandleRequestSort = (property: string) => () => {
    const newOrder = orderBy === property && order === 'asc' ? 'desc' : 'asc'
    setOrder(newOrder)
    setOrderBy(property)
    onChangeOrder?.(property, newOrder)
  }

  const onHandleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const onHandleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const rows = parseInt(event.target.value, 10)
    setRowsPerPage(rows)
    setPage(0)
    onChangeRowsPerPage?.(rows)
  }

  const isValueExistInRow = (row: any) => {
    const even = (column: string) => {
      const currentColumn: CuiColumn | undefined = columns.find(
        x => x.id === column
      )
      if (currentColumn) {
        const columnName = currentColumn.propertyKey || currentColumn.id
        const value = currentColumn?.format
          ? currentColumn.format(row[columnName])
          : getDescendantProp(row, columnName)
        return (
          value && value.toString().toLowerCase().indexOf(searchValue) !== -1
        )
      }
    }
    return even
  }

  const filteredRows: any[] =
    searchValue === ''
      ? rows
      : rows.filter((row: any) => {
          return searchColumns && searchColumns.some(isValueExistInRow(row))
        })

  const getFormatOrderBy = useCallback(() => {
    const orderByColumn: CuiColumn | undefined = columns.find(column => {
      if (column.id === orderBy) return column
      return null
    })
    return {
      keyProperty: orderByColumn?.propertyKey || orderByColumn?.id,
      format:
        orderByColumn?.format && !orderByColumn?.isOrderByWithoutFormat
          ? orderByColumn.format
          : null
    }
  }, [columns, orderBy])

  const onRowCheckClick = useCallback(
    (row: any) => {
      if (keyColumn) {
        const key = row[keyColumn]
        const selectedIndex = selected.indexOf(key)
        let newSelected: ReactText[] = []

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, key)
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1)
          )
        }
        onRowCheck && onRowCheck(newSelected)
      }
    },
    [selected, keyColumn, onRowCheck]
  )

  const onSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && keyColumn) {
      const newSelecteds = filteredRows.flatMap(n =>
        !disableRow || (disableRow && !disableRow(n)) ? n[keyColumn] : []
      )
      onRowCheck && onRowCheck(newSelecteds)
    } else {
      onRowCheck && onRowCheck([])
    }
  }

  useEffect(() => {
    if (!leavePositionOnRefresh) {
      setPage(0)
      setSearchValue('')
    }
  }, [rows, leavePositionOnRefresh])

  const isSelected = useCallback(
    (key: ReactText) => selected.indexOf(key) !== -1,
    [selected]
  )

  const formatOrderBy = getFormatOrderBy()
  const displayRows = tableSort(
    filteredRows,
    getComparator(order, formatOrderBy.keyProperty!, formatOrderBy.format)
  ).slice(
    page * rowsPerPage,
    rowsPerPage === -1 ? filteredRows.length : page * rowsPerPage + rowsPerPage
  )

  const tableData = {
    rows: displayRows,
    columns,
    onRowClick: onRowClick,
    onRowCheck: onRowCheckClick,
    onRowEdit: onRowEdit,
    hover,
    checkboxColumn,
    editColumn,
    keyColumn,
    isSelected: isSelected,
    disableRow: disableRow,
    disableActionRow: disableActionRow,
    onRowRender,
    collapseRow,
    clearSelectedRow
  }
  const component = windowing ? 'div' : undefined

  return (
    <>
      {searchColumns && (
        <CuiTableSearch
          value={searchValue}
          onChangeValue={newValue => {
            setPage(0)
            setSearchValue(newValue)
            onRowCheck?.([])
          }}
          placeholder={searchPlaceHolder}
        />
      )}
      <StyledTableContainer
        style={{
          height: maxHeight,
          maxHeight: maxHeight,
          overflow: windowing ? 'hidden' : undefined
        }}
        className={denseUI ? classes.denseUI : undefined}
      >
        <Table
          component={component || 'table'}
          stickyHeader={stickyHeader}
          className={classes.table}
        >
          <TableHead component={component || 'thead'}>
            <TableRow component={component || 'tr'}>
              {checkboxColumn && (
                <TableCell component={component || 'td'} padding="checkbox">
                  <Checkbox
                    indeterminate={
                      selected.length > 0 &&
                      selected.length <
                        filteredRows.filter(
                          n => !disableRow || (disableRow && !disableRow(n))
                        ).length
                    }
                    checked={
                      filteredRows.filter(
                        n => !disableRow || (disableRow && !disableRow(n))
                      ).length > 0 &&
                      selected.length ===
                        filteredRows.filter(
                          n => !disableRow || (disableRow && !disableRow(n))
                        ).length
                    }
                    disabled={selectAllCheckboxDisabled}
                    onChange={onSelectAllClick}
                    color={'primary'}
                  />
                </TableCell>
              )}
              {collapseRow && <TableCell></TableCell>}
              {columns.map(col => {
                const column = fillColumnDefaultValues(col)
                return (
                  <TableCell
                    component={component || 'td'}
                    variant="head"
                    key={column.id}
                    align={column.align}
                    style={{
                      minWidth: column.minWidth,
                      width: column.width
                    }}
                    className={classNames(
                      windowing && classes.windowsingCell,
                      !column.width &&
                        !column.minWidth &&
                        windowing &&
                        classes.windowsingExpandingCell
                    )}
                    sortDirection={orderBy === column.id ? order : false}
                  >
                    {!column.isSort ? (
                      column.label || ''
                    ) : (
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={onHandleRequestSort(column.id)}
                      >
                        {column.label || ''}
                      </TableSortLabel>
                    )}
                  </TableCell>
                )
              })}
              {editColumn && (
                <TableCell
                  component={component || 'td'}
                  padding="checkbox"
                ></TableCell>
              )}
            </TableRow>
          </TableHead>
          <CuiTableBody
            {...tableData}
            windowing={windowing}
            maxHeight={maxHeight}
            rowsPerPage={rowsPerPage}
            loading={loading}
          />
        </Table>
      </StyledTableContainer>
      {hasPaging && (
        <StyledTablePagination
          className={classesPaginig.tablePagination}
          rowsPerPageOptions={
            rowsPerPageOptions ? rowsPerPageOptions : defaultRowPerPageOptions
          }
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: {'aria-label': 'rows per page'},
            native: true
          }}
          onPageChange={onHandleChangePage}
          onRowsPerPageChange={onHandleChangeRowsPerPage}
          ActionsComponent={CuiTablePaginationActions}
        />
      )}
    </>
  )
}
