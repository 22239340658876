import {ContainerClient} from '@azure/storage-blob'
import {getLocalDateFromString} from 'src/utils/formatDate'

export interface AzureFile {
  name: string
  fullPath?: string
  type: FileType
  lastModified?: Date | null
  isParentFolder?: boolean
  blob?: Blob
  children?: AzureFile[]
  oldPath?: string
}

export interface SasProps {
  sas: string
  sasExpiration: Date
}

export enum FileType {
  Folder,
  Pdf,
  Xls,
  Xlsx,
  Doc,
  Docx,
  Jpg,
  Jpeg,
  Png,
  Tif,
  Msg,
  Mp4,
  Avi
}

export function SASExpired(sasExpiration?: Date) {
  return (
    !sasExpiration ||
    Math.round(
      (new Date(sasExpiration).getTime() - new Date().getTime()) / 60000
    ) < 10
  )
}

export async function getSAS(
  url: string,
  containerName: string,
  fetcher?: typeof fetch
) {
  const response = await (fetcher || fetch)(url, {
    method: 'POST',
    body: JSON.stringify({name: containerName})
  })
  const sas = await response.text()
  if (!sas) return Promise.reject({code: 401, msg: 'Unauthorized'})
  const params = new URLSearchParams(sas)
  const sasExpiration = new Date(params.get('se') || '')
  return {
    sas,
    sasExpiration
  } as SasProps
}

export async function getListInCurrentDir(
  client: ContainerClient | undefined,
  directorty?: string
) {
  const fileList: AzureFile[] = []
  if (!client) return fileList
  let dirIter = client.listBlobsByHierarchy('/', {
    prefix: directorty,
    includeMetadata: true
  })

  for await (const item of dirIter) {
    if (item.name.endsWith('$$$.$$$')) continue

    let lastModified =
      item.kind === 'blob' && item.metadata && item.metadata['datemodified']

    const file = getAzureFile(
      item.name,
      item.kind === 'prefix',
      lastModified || undefined
    )
    fileList.push(file)
  }

  return fileList
}

export async function getWithModifiedDate(
  client: ContainerClient | undefined,
  file: AzureFile
) {
  if (!client) return file

  if (file.type === FileType.Folder) {
    try {
      const folderProperties = await client
        .getBlockBlobClient(file.fullPath + '$$$.$$$')
        .getProperties()
      const lastModified =
        folderProperties.metadata && folderProperties.metadata['datemodified']
      file.lastModified =
        (lastModified && getLocalDateFromString(lastModified)) || null
    } catch (error) {
      file.lastModified = null
    }
  }
  return file
}

export function isFolder(path: string) {
  return path.slice(-1) === '/'
}

export function getName(
  path: string,
  isFolder: boolean,
  isGetWithExtension = true
) {
  const subDirctoryIndex = path.indexOf('/')
  if (subDirctoryIndex >= 0) {
    path = isFolder ? path.slice(0, path.lastIndexOf('/')) : path
    const index = path.lastIndexOf('/')
    path = path.substring(index + 1) || '<no name>'
  }

  return !isFolder && !isGetWithExtension
    ? path.substring(0, path.lastIndexOf('.'))
    : path
}

export function getAzureFile(
  path: string,
  isFolder: boolean,
  lastModified?: string
): AzureFile {
  return {
    type: getFileType(path, isFolder),
    fullPath: path,
    name: getName(path, isFolder),
    lastModified: (lastModified && getLocalDateFromString(lastModified)) || null
  }
}

export function getFileExtension(path: string) {
  return path.substring(path.lastIndexOf('.') + 1)
}

export function getFileType(path: string, isFolder: boolean) {
  if (isFolder) return FileType.Folder
  const type = getFileExtension(path)
  return FileType[
    (type[0].toUpperCase() +
      type.slice(1).toLowerCase()) as keyof typeof FileType
  ]
}

export function getParentFolderByPath(path: string) {
  let isFolder = path.endsWith('/')
  return getParentFolder(path, isFolder)
}

export function getParentFolder(path: string, isPathFolder?: boolean) {
  isPathFolder = isPathFolder === undefined ? isFolder(path) : isPathFolder
  path = isPathFolder ? path.slice(0, path.lastIndexOf('/')) : path
  return path.indexOf('/') > 0 ? path.slice(0, path.lastIndexOf('/') + 1) : ''
}

export function getHierarchy(path: string) {
  if (!path) return []
  const pathType = getFileType(path, path.endsWith('/'))
  return path.split('/').reduce((prev, next, index, arr) => {
    if (next) {
      const fullPath = prev.length
        ? prev[prev.length - 1].fullPath + next
        : next
      const type = index === arr.length - 1 ? pathType : FileType.Folder
      prev.push({name: next, fullPath: fullPath + '/', type: type})
    }
    return prev
  }, new Array<AzureFile>())
}

export function getUploadMediaTypesList() {
  return {
    'application/msword': ['.doc', '.docx'],
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
      '.docx'
    ],
    'application/pdf': ['.pdf'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
      '.xlsx'
    ],
    'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
    'application/vnd.ms-outlook': ['.msg'],
    'image/jpeg': ['.jpeg', '.jpg']
  }
}

export async function copyBlob(
  client: ContainerClient,
  srcPath: string,
  destPath: string,
  removeSource: boolean = true
) {
  const blockBlobClientDest = client.getBlockBlobClient(destPath)
  const blockBlobClientSrc = client.getBlockBlobClient(srcPath)
  const response = await blockBlobClientDest.beginCopyFromURL(
    blockBlobClientSrc.url
  )
  const result = await response.pollUntilDone()
  if (result._response.status === 202) {
    if (!removeSource) return true
    if (await client.getBlockBlobClient(srcPath).delete()) {
      return true
    }
  }
  return false
}

export function getMediaTypes(type: FileType) {
  switch (type) {
    case FileType.Doc:
      return 'application/msword'
    case FileType.Docx:
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    case FileType.Jpeg:
      return 'image/jpeg'
    case FileType.Jpg:
      return 'image/jpeg'
    case FileType.Pdf:
      return 'application/pdf'
    case FileType.Xls:
      return 'application/vnd.ms-excel'
    case FileType.Xlsx:
      return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    default:
      return
  }
}

export async function setParentDate(
  client: ContainerClient,
  path: string,
  dateModify: string,
  abortSignal?: AbortSignal
) {
  try {
    let newPath = path.replace(/\/$/, '')
    const pathParts: string[] = newPath.split('/')
    const copyPromises: Promise<void>[] = []

    for (let i = pathParts.length - 1; i > 0; i--) {
      copyPromises.push(
        new Promise(async (resolve, reject) => {
          newPath =
            newPath.substring(0, newPath.lastIndexOf(pathParts[i])) + '$$$.$$$'
          try {
            await client
              .getBlockBlobClient(newPath)
              .setMetadata(
                {datemodified: dateModify},
                {abortSignal: abortSignal}
              )
            resolve()
          } catch {
            reject()
          }
        })
      )
    }
    await Promise.all(copyPromises)
  } catch (error) {}
}

export function getPdfOfWordPath(path: string) {
  return path.replace(/(.docx|.doc)$/, '.pdf')
}

export function isWordFile(file: AzureFile) {
  return file.type === FileType.Doc || file.type === FileType.Docx
}

export function isWordType(fileType: FileType) {
  return fileType === FileType.Doc || fileType === FileType.Docx
}

export function getDestPath(originalPath: string, destPath: string = '') {
  const isSrcFolder = isFolder(originalPath || '')
  return (
    destPath + getName(originalPath, isSrcFolder) + (isSrcFolder ? '/' : '')
  )
}

export function getSharedParentFolder(files: string[]) {
  if (files.length === 1) {
    return getParentFolderByPath(files[0])
  }

  files = files.sort()
  let ret = ''
  let index = 0
  const f1 = files[0].split('/')
  const f2 = files[files.length - 1].split('/')
  while (index < f1.length && index < f2.length && f1[index] === f2[index]) {
    ret += f1[index] + '/'
    index++
  }
  return ret
}
