import {useCallback, useState} from 'react'
import useAzureBlobClient from 'src/hooks/useAzureBlobClient'
import {BlockBlobUploadResponse} from '@azure/storage-blob'
import {setParentDate} from 'src/utils/AzureStorage'
import {Promise} from 'es6-promise'

export interface AzureResult {
  data: BlockBlobUploadResponse
  isSucceeded: boolean
}

export interface UploadFileProps {
  blob: Blob
  filePath: string
}

export interface Uploading {
  uploading: boolean
  count: number
  uploadedCounter: number
  uploadedPercent: number
  uploadPath: string
}

export interface useAzureUploadResult {
  uploadFile: (
    files: UploadFileProps[],
    abortSignal?: AbortSignal
  ) => globalThis.Promise<boolean | undefined>
  isUploading: boolean
  uploadedCounter: number
  uploadedPercent: number
  uploadPath: string
}

export default function useAzureUpload(
  storageName: string,
  containerName: string
): useAzureUploadResult {
  const {getClient} = useAzureBlobClient(storageName, containerName)
  const [uploading, setUploading] = useState<Uploading>({
    uploading: false
  } as Uploading)
  const uploadOneFile = useCallback(
    async (
      file: UploadFileProps,
      filesLength: number,
      abortSignal?: AbortSignal
    ) => {
      try {
        setUploading(prev => ({
          ...prev,
          uploadedPercent: prev.uploadedPercent + (1 / filesLength) * 100 * 0.2
        }))
        const client = await getClient()
        if (!client) {
          return false
        }
        setUploading(prev => ({
          ...prev,
          uploadedPercent: prev.uploadedPercent + (1 / filesLength) * 100 * 0.2
        }))

        const blockBlobClient = client.getBlockBlobClient(file.filePath)
        const dateModified = new Date().toUTCString()
        console.log('save file:before send file ' + file.filePath)

        const data = await blockBlobClient.upload(file.blob, file.blob.size, {
          metadata: {datemodified: dateModified},
          abortSignal: abortSignal
        })
        // console.log('save file:send file succces' + file.filePath)
        setUploading(prev => ({
          ...prev,
          uploadedPercent: prev.uploadedPercent + (1 / filesLength) * 100 * 0.4
        }))

        const res = !data.errorCode
        if (res)
          setUploading(prev => ({
            ...prev,
            uploadedCounter: prev.uploadedCounter + 1,
            uploadedPercent:
              prev.uploadedPercent + (1 / filesLength) * 100 * 0.2,
            uploadPath: file.filePath
          }))

        await setParentDate(client, file.filePath, dateModified, abortSignal)
        return res
      } catch (error) {
        console.error(error)
        if (abortSignal?.aborted) return undefined
        return false
      }
    },
    [getClient]
  )

  const uploadFile = useCallback(
    async (files: UploadFileProps[], abortSignal?: AbortSignal) => {
      const maxConcurrentUploads = 3

      setUploading({
        uploading: true,
        count: files.length,
        uploadedCounter: 0,
        uploadedPercent: 0,
        uploadPath: ''
      })

      const queue = files.map(
        file => () => uploadOneFile(file, files.length, abortSignal)
      )

      const active = new Array(maxConcurrentUploads)
        .fill(null)
        .map(async () => {
          while (queue.length > 0) {
            const task = queue.shift()
            if (task) await task()
          }
        })

      await Promise.all(active)
      setUploading(prev => ({
        ...prev,
        uploading: false
      }))

      return true
    },
    [uploadOneFile]
  )

  return {
    uploadFile,
    isUploading: uploading.uploading,
    uploadedCounter: uploading.uploadedCounter,
    uploadedPercent: uploading.uploadedPercent,
    uploadPath: uploading.uploadPath
  }
}
