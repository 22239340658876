import React, {useCallback, useEffect, useState} from 'react'
import {styled} from '@mui/material/styles'
import {CuiColumn, CuiRowProps} from 'src/components/custom/table/CuiTable'
import {
  TableCell,
  TableRow,
  Checkbox,
  Box,
  IconButton,
  Collapse,
  TableRowProps
} from '@mui/material'
import CuiOverflowTooltip from 'src/components/custom/table/CuiOverflowTooltip'
import {getDescendantProp} from 'src/components/custom/table/CuiTableSort'
import {grey} from '@mui/material/colors'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {tableRowClasses} from '@mui/material'
import classNames from 'classnames'
import EditIcon from '@mui/icons-material/Edit'

const lightGreyColor = grey['100']
const greyColor = grey['500']

const PREFIX = 'CuiTableRow'

const classes = {
  selected: `${PREFIX}-selected`,
  disable: `${PREFIX}-disable`,
  cursorDefault: `${PREFIX}-cursorDefault`,
  windowingCell: `${PREFIX}-windowingCell`,
  windowingExpandingCell: `${PREFIX}-windowingExpandingCell`,
  windowingRow: `${PREFIX}-windowingRow`,
  windowingRowCell: `${PREFIX}-windowingRowCell`
}
const StyledTableRow = styled(
  (props: TableRowProps & {component: 'div' | 'tr'}) => {
    return <TableRow {...props} component={'div'}></TableRow>
  }
)(({theme}) => ({
  [`&.${tableRowClasses.selected}.${tableRowClasses.root},
  &.${tableRowClasses.selected}.${tableRowClasses.root}:hover`]: {
    backgroundColor: lightGreyColor
  },
  [`& .${classes.disable}`]: {
    color: greyColor,
    [`&.${tableRowClasses.selected}.${tableRowClasses.root},
    &.${tableRowClasses.selected}.${tableRowClasses.root}:hover`]: {
      backgroundColor: 'white'
    }
  },
  [`&.${classes.cursorDefault}`]: {
    cursor: 'default'
  },
  [`&.${classes.windowingRow}`]: {
    display: 'flex'
  },
  [`& .${classes.windowingRowCell}`]: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  [`& .${classes.windowingCell}`]: {
    flexGrow: 0,
    flexShrink: 0
  },
  [`& .${classes.windowingExpandingCell}`]: {
    flex: 1,
    width: 100
  }
}))

export default function CuiTableRow({
  row,
  columns,
  onRowClick,
  onRowCheck,
  onRowRender,
  onRowEdit,
  hover,
  isSelected = false,
  checkboxColumn,
  editColumn,
  isDisabled,
  isDisabledAction,
  clearSelectedRow,
  style,
  windowingClasses,
  collapseRow
}: CuiRowProps) {
  const [isCollapseOpen, setIsCollapseOpen] = React.useState(false)
  const [selectedRow, setSelectedRow] = useState()

  const getValue = useCallback(
    (property: any, column: CuiColumn) => {
      if (column.node) {
        return column.node(row)
      }
      return column.format ? column.format(property) : property
    },
    [row]
  )

  useEffect(() => {
    if (selectedRow) {
      onRowClick?.(selectedRow)
      clearSelectedRow && setSelectedRow(undefined)
    }
  }, [selectedRow, clearSelectedRow, onRowClick])

  useEffect(() => {
    onRowRender?.(row)
  }, [onRowRender, row])

  return (
    <>
      <StyledTableRow
        component={windowingClasses ? 'div' : 'tr'}
        classes={{
          root: classNames(
            windowingClasses ? classes.windowingRow : undefined,
            onRowClick ? classes.cursorDefault : undefined
          ),
          selected: !isDisabled ? classes.selected : classes.disable
        }}
        hover={hover}
        tabIndex={-1}
        onClick={() => !isDisabled && setSelectedRow(row)}
        selected={isSelected}
        style={style}
      >
        {checkboxColumn && (
          <TableCell
            component={windowingClasses ? 'div' : 'td'}
            className={windowingClasses ? classes.windowingRowCell : undefined}
          >
            <Checkbox
              checked={isSelected}
              disabled={isDisabled || isDisabledAction}
              value={row.fullPath}
              inputProps={{'aria-label': row.fullPath}}
              color={'primary'}
              onClick={event => {
                event.stopPropagation()
                event.preventDefault()
                onRowCheck && onRowCheck(row)
              }}
            />
          </TableCell>
        )}
        {collapseRow && (
          <TableCell component={windowingClasses ? 'div' : 'td'}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => {
                setIsCollapseOpen(!isCollapseOpen)
              }}
            >
              {isCollapseOpen ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )}
            </IconButton>
          </TableCell>
        )}
        {columns.map(column => {
          const property = getDescendantProp(
            row,
            column.propertyKey || column.id
          )
          const value = getValue(property, column)
          return (
            <TableCell
              component={windowingClasses ? 'div' : 'td'}
              key={column.id}
              align={column.align}
              classes={{root: (isDisabled && classes.disable) || undefined}}
              {...(windowingClasses
                ? {
                    style: {
                      minWidth: column.minWidth,
                      width: column.width
                    },
                    className: classNames(
                      classes.windowingCell,
                      classes.windowingRowCell,
                      !column.width &&
                        !column.minWidth &&
                        classes.windowingExpandingCell
                    )
                  }
                : {style: {maxWidth: 0}})}
            >
              {column.node ? (
                <Box width="100%" key={column.id}>
                  {value}
                </Box>
              ) : (
                <CuiOverflowTooltip
                  key={column.id}
                  value={value}
                  title={column.node ? column.label : value}
                />
              )}
            </TableCell>
          )
        })}
        {editColumn && (
          <TableCell
            component={windowingClasses ? 'div' : 'td'}
            className={windowingClasses ? classes.windowingRowCell : undefined}
          >
            <EditIcon
              sx={{cursor: 'pointer'}}
              onClick={() => {
                onRowEdit && onRowEdit(row)
              }}
            ></EditIcon>
          </TableCell>
        )}
      </StyledTableRow>
      {collapseRow && (
        <TableRow>
          <TableCell
            style={{paddingBottom: 0, paddingTop: 0}}
            colSpan={columns.length}
          >
            <Collapse in={isCollapseOpen} timeout="auto" unmountOnExit>
              {collapseRow(row)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  )
}
