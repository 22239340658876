import {BaseResource} from 'src/entities/BaseResource'
import {ItemStatus, ItemType, ItemTypeGroupId} from 'src/entities/Item'
import {round} from 'src/utils/numberHelper'
import {BaseInvoiceResource} from './Invoice'

export interface BillingTaskType extends BaseResource {
  projectTaskTypeId: number
  typeName: string
  rate: number
  isHourly: boolean
  tasks: BillingTask[]
}

export interface BillingTask extends BaseResource {
  taskId: number
  taskName: string
  docsInLeaseFile: number
  isExtraPaid: boolean
  amount: number
  specialRate?: number
  deliverables: number
  totalHours: number
  status: ItemStatus
  propertyName: string
  buildingName: string
  tenant: string
  invoice: BaseInvoiceResource
  isChecked: boolean
  type: ItemType
}

export interface BillingInfo {
  discount: number
  discountPercent: number
  globalRate: number
  amendmentRate: number
  chargeAmendmentsFrom: number
  splitInvoiceBy: SplitInvoiceBy
}

export enum SplitInvoiceBy {
  None = 1,
  PropertyName,
  BuildingName,
  Item
}

export enum SplitInvoiceByMapping {
  projectId = 1,
  propertyName,
  buildingName,
  taskName
}

export const getBaseRate = (
  billingTaskType: BillingTaskType,
  task: BillingTask
) => {
  const rate = billingTaskType.isHourly
    ? ((task?.specialRate !== null && task?.specialRate !== undefined
        ? task?.specialRate || 0
        : billingTaskType.rate) *
        Math.round(task.totalHours * 100)) /
      100
    : (task?.specialRate !== null && task?.specialRate !== undefined
        ? task?.specialRate || 0
        : billingTaskType.rate) * (task?.deliverables || 1)
  return round(rate)
}

export const getAmendmenetsCharged = (
  task: BillingTask,
  chargeAmendmentsFrom: number
) => {
  const chargeAmendmentsFromCalculated = chargeAmendmentsFrom || 1
  if (
    !task.docsInLeaseFile ||
    task.docsInLeaseFile < chargeAmendmentsFromCalculated
  )
    return 0
  return task.docsInLeaseFile - chargeAmendmentsFromCalculated + 1
}

export const getAmendmenetsRate = (
  task: BillingTask,
  chargeAmendmentsFrom: number,
  amendmentRate: number
) => {
  return getAmendmenetsCharged(task, chargeAmendmentsFrom) * amendmentRate
}

export const getTaskAmount = (
  billingTaskType: BillingTaskType,
  task: BillingTask,
  chargeAmendmentsFrom: number,
  amendmentRate: number
) => {
  var amount =
    getBaseRate(billingTaskType, task) +
    getAmendmenetsRate(task, chargeAmendmentsFrom, amendmentRate)
  return amount
}

export const getAverageTime = (tasks: BillingTask[]) => {
  return (
    round(
      tasks.reduce((sum, task) => sum + task.totalHours, 0) / tasks.length
    ) || 0
  )
}

export const getFilteredItems = (
  tasks: BillingTask[],
  isExtraPaid: boolean,
  showCompleted: boolean
) => {
  return tasks.filter(
    t =>
      t.isExtraPaid === isExtraPaid &&
      (showCompleted || (!showCompleted && isTaskCompletedForInvoice(t)))
  )
}

export const isTaskCompletedForInvoice = (task: BillingTask) =>
  task.status === ItemStatus.SentToClient ||
  (task.status === ItemStatus.Closed &&
    task.type.group?.id === ItemTypeGroupId.RD &&
    !Boolean(task.invoice))

export const getNextInvoiceCount = (tasks: BillingTask[]) => {
  return tasks.reduce((count, t) => {
    return count + (isTaskCompletedForInvoice(t) ? 1 : 0)
  }, 0)
}

export const getDeliverablesCount = (tasks: BillingTask[]) => {
  return tasks.reduce((count, t) => {
    return count + (ItemStatus.Completed & t.status ? 1 : 0)
  }, 0)
}

export const getLoggedTime = (tasks: BillingTask[]) => {
  return round(
    tasks.reduce((sum, t) => {
      return sum + t.totalHours
    }, 0)
  )
}

export const getDeliverablesLoggedTime = (tasks: BillingTask[]) => {
  return round(
    tasks.reduce((sum, t) => {
      return sum + (ItemStatus.Completed & t.status ? t.totalHours : 0)
    }, 0)
  )
}

export const getNextInvoiceLoggedTime = (tasks: BillingTask[]) => {
  return round(
    tasks.reduce((sum, t) => {
      return sum + (isTaskCompletedForInvoice(t) ? t.totalHours : 0)
    }, 0)
  )
}
