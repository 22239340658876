import React from 'react'
import {ListItem, ListItemText} from '@mui/material'
import CuiNumberFormat from 'src/components/custom/CuiNumberFormat'
import {} from 'src/entities/Item'
import {
  BillingInfo,
  BillingTask,
  BillingTaskType,
  getTaskAmount,
  getAmendmenetsCharged
} from 'src/entities/BillingInfo'
import {round} from 'src/utils/numberHelper'

interface InvoiceDetailsTaskFieldsProps {
  billingInfo: BillingInfo
  task: BillingTask
  taskType?: BillingTaskType
}

export default function InvoiceDetailsTaskFields({
  billingInfo,
  task,
  taskType
}: InvoiceDetailsTaskFieldsProps) {
  return (
    <ListItem dense sx={{width: '100%'}}>
      <ListItemText sx={{width: '20%'}} primary={task.taskName} />
      {!task.specialRate && taskType?.isHourly && (
        <ListItemText
          sx={{width: '12%'}}
          primary={
            !task.specialRate &&
            taskType?.isHourly &&
            round(task.totalHours) + ' Hours'
          }
        />
      )}
      {(!taskType?.isHourly || !!task.specialRate) && (
        <ListItemText
          sx={{width: '12%'}}
          primary={
            (!taskType?.isHourly || task.specialRate) &&
            (task.deliverables || 1) + ' Deliverables'
          }
        />
      )}
      <ListItemText sx={{width: '3%'}} primary={'*'} />
      <ListItemText
        sx={{width: '10%'}}
        primary={
          <CuiNumberFormat
            value={
              task.specialRate !== null && task.specialRate !== undefined
                ? task.specialRate
                : taskType?.rate
            }
            displayType={'text'}
            addPrefix
          />
        }
      />
      <ListItemText sx={{width: '4%'}} primary={'+'} />
      <ListItemText
        sx={{width: '15%'}}
        primary={
          getAmendmenetsCharged(task, billingInfo?.chargeAmendmentsFrom) +
          ' Amendments'
        }
      />
      <ListItemText sx={{width: '3%'}} primary={'*'} />
      <ListItemText
        sx={{width: '15%'}}
        primary={
          <CuiNumberFormat
            value={billingInfo.amendmentRate}
            displayType={'text'}
            addPrefix
          />
        }
      />
      <ListItemText
        sx={{width: '15%'}}
        primary={
          taskType && (
            <CuiNumberFormat
              value={getTaskAmount(
                taskType,
                task,
                billingInfo?.chargeAmendmentsFrom,
                billingInfo?.amendmentRate
              )}
              displayType={'text'}
              addPrefix
            />
          )
        }
      />
    </ListItem>
  )
}
