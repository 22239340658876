import React from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete, {
  AutocompleteChangeReason,
  createFilterOptions
} from '@mui/material/Autocomplete'
import {Box, Checkbox, CircularProgress, Typography} from '@mui/material'

interface CuiMultiSelectProps {
  items: any[]
  isSelectAll?: boolean
  selectedValues: any[]
  label: string
  placeholder?: string
  selectAllLabel?: string
  noOptionsText?: React.ReactNode
  limitTags: number
  onToggleOption: (event: any, selectedOptions: any[]) => void
  onClearOptions: () => void
  onSelectAll?: (allSelected: boolean) => void
  onBlur?: () => void
  getOptionLabel: (option: any) => string
  groupBy?: (option: any) => string
  chipStyle?: any
  isDataLoading?: boolean
  loadingInStartAdornment?: boolean
  loadingMessage?: string
  size?: 'small' | 'medium'
  error?: boolean
  helperText?: React.ReactNode
  externalButton?: React.ReactNode
  disableBorder?: boolean
  disabled?: boolean
}

const CuiMultiSelect = ({
  items,
  isSelectAll,
  selectedValues,
  label,
  placeholder,
  selectAllLabel,
  noOptionsText,
  limitTags,
  onToggleOption,
  onClearOptions,
  onSelectAll,
  onBlur,
  getOptionLabel,
  groupBy,
  chipStyle,
  isDataLoading,
  loadingInStartAdornment,
  loadingMessage,
  size,
  error,
  helperText,
  externalButton,
  disableBorder,
  disabled
}: CuiMultiSelectProps) => {
  const allSelected = items.length === selectedValues.length
  const handleToggleSelectAll = () => {
    onSelectAll && onSelectAll(!allSelected)
  }

  const handleChange = (
    event: any,
    selectedOptions: any,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (
        selectedOptions.find((option: any) => option.value === 'select-all')
      ) {
        handleToggleSelectAll()
      } else {
        onToggleOption && onToggleOption(event, selectedOptions)
      }
    } else if (reason === 'clear') {
      onClearOptions && onClearOptions()
    }
  }

  const inputRenderer = (params: any) => {
    const {InputProps} = params
    const {startAdornment, ...restInputProps} = InputProps
    const margin = loadingInStartAdornment && <Box pr={1.5} />
    const loading = (
      <>
        {margin}
        <CircularProgress color="primary" size={20} />
        {margin}
        <Typography color="primary">{loadingMessage}</Typography>
      </>
    )
    return (
      <TextField
        {...params}
        label={label}
        placeholder={placeholder}
        variant={disableBorder ? 'standard' : 'outlined'}
        size={size}
        error={error}
        helperText={helperText}
        InputProps={{
          ...restInputProps,
          disableUnderline: disableBorder,
          startAdornment:
            isDataLoading && loadingInStartAdornment
              ? loading
              : startAdornment && (
                  <Box maxHeight="21vh" overflow="auto">
                    {startAdornment}
                  </Box>
                ),
          endAdornment:
            isDataLoading && !loadingInStartAdornment ? (
              loading
            ) : (
              <>{params.InputProps.endAdornment}</>
            )
        }}
      />
    )
  }

  const getOptionSelected = (option: any, previousSelectedOption: any) => {
    return option.id === previousSelectedOption.id
  }
  const filter = createFilterOptions()
  return (
    <>
      <Autocomplete
        multiple
        disabled={disabled}
        size={size}
        limitTags={limitTags}
        ChipProps={{style: chipStyle}}
        options={items}
        value={selectedValues}
        groupBy={groupBy}
        disableCloseOnSelect
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={getOptionSelected}
        noOptionsText={noOptionsText ? noOptionsText : 'No options'}
        fullWidth
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          return isSelectAll
            ? [
                {
                  name: selectAllLabel ? selectAllLabel : 'Select all',
                  value: 'select-all'
                },
                ...filtered
              ]
            : [...filtered]
        }}
        onChange={handleChange}
        onBlur={onBlur}
        renderOption={(props, option, {selected}) => (
          <li {...props}>
            <Checkbox
              style={{marginRight: 8}}
              checked={option.value !== 'select-all' ? selected : allSelected}
            />
            {getOptionLabel(option)}
          </li>
        )}
        renderInput={inputRenderer}
      />
      {externalButton && externalButton}
    </>
  )
}

CuiMultiSelect.defaultProps = {
  limitTags: 5,
  items: [],
  selectedValues: [],
  getOptionLabel: (value: any) => value
}

export default CuiMultiSelect
