import InvoiceAttachment, {
  AttachmentType,
  MultiInvoiceAttachments,
  LoadingFile
} from 'src/entities/InvoiceAttachment'
import React, {useState, useCallback} from 'react'
import {
  List,
  ListItem,
  ListItemText,
  styled,
  Dialog,
  DialogContent,
  ListItemButton,
  Stack,
  Button
} from '@mui/material'
import {ReactComponent as DownloadIcon} from 'src/images/Download.svg'
import {ReactComponent as DeleteIcon} from 'src/images/Delete.svg'
import useAzureUpload, {UploadFileProps} from 'src/hooks/useAzureUpload'
import config from 'src/config'
import CuiSnackbarMessage from 'src/components/custom/CuiSnackbarMessage'
import {useAuth} from 'src/context/Auth'
import LoadingProp from 'src/entities/Loading'
import {getName, getFileExtension} from 'src/utils/AzureStorage'
import useAzureDownloadAndOpen from 'src/hooks/useAzureDownloadAndOpen'
import Invoice from 'src/entities/Invoice'
import {GridRowId} from '@mui/x-data-grid-pro'
import CuiProgressButton from 'src/components/custom/CuiProgressButton'
import CuiConfirmDialog from 'src/components/custom/CuiConfirmDialog'
import File from 'src/entities/File'
import {UserRole} from 'src/entities/User'

interface InvoiceTableAttachmentModalProps {
  invoice: Invoice
  invoiceIds: GridRowId[]
  open: boolean
  onClose: (attachment: InvoiceAttachment | null, isDeleted?: boolean) => void
}

const Input = styled('input')({
  display: 'none'
})

const containerName = config.azureInvoiceAttachmentsBlobStorage.container
const storageName = config.azureInvoiceAttachmentsBlobStorage.name

export default function InvoiceTableAttachmentModal({
  invoice,
  invoiceIds,
  open,
  onClose
}: InvoiceTableAttachmentModalProps) {
  const {fetchWithUser, roles} = useAuth()
  const [loadingFile, setLoadingFile] = useState<LoadingProp>({})
  const [downloadFile, setDownloadFile] = useState<File | null>(null)
  const [deletingFile, setDeletingFile] = useState<LoadingFile | null>({})
  const {uploadFile} = useAzureUpload(storageName, containerName)
  const {openInNewTab} = useAzureDownloadAndOpen(storageName, containerName)

  const openAttachment = useCallback(
    async (file: File) => {
      setDownloadFile(file)
      await openInNewTab(file.path)
      setDownloadFile(null)
    },
    [openInNewTab]
  )

  const SaveInvoiceAttchment = useCallback(
    (file: any, fileName: string) => {
      fetchWithUser(`${config.apiUrl}/invoiceAttachment/multi`, {
        method: 'POST',
        body: JSON.stringify({
          invoiceIds: invoiceIds,
          file: {path: fileName}
        } as MultiInvoiceAttachments),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      })
        .then(response => {
          return response.json()
        })
        .then(data => {
          data &&
            setLoadingFile({
              loading: false,
              message: 'Uploading files succesfuly'
            })
        })
        .finally(() => {
          setLoadingFile({loading: false})
          onClose({file: {path: fileName}} as InvoiceAttachment)
        })
    },
    [fetchWithUser, onClose, invoiceIds]
  )

  const DeleteInvoiceAttchment = useCallback(
    (attachment: InvoiceAttachment) => {
      setDeletingFile({loading: true})
      const url =
        `${config.apiUrl}/invoiceAttachment` +
        (attachment.id ? `/${attachment.id}` : `/bypath/${invoice.id}`)

      fetchWithUser(url, {
        method: 'DELETE',
        body: JSON.stringify(attachment?.file?.path),
        headers: {
          'Content-type': 'application/json; charset=UTF-8'
        }
      })
        .then(response => {
          return response.json()
        })
        .then(() => {
          onClose(attachment, true)
          // const data = deleteFile(attachment?.file?.path)
          // if (!data) {
          //   setDeletingFile({isFailed: true, errorMessage: 'Error delete file'})
          // } else {
          //   setDeletingFile({
          //     loading: false,
          //     message: 'File was deleted succesfuly'
          //   } )
          //   onClose(attachment, true)
          // }
        })
        .finally(() => {
          setDeletingFile({})
        })
    },
    [fetchWithUser, onClose, invoice.id]
  )

  const AddFile = useCallback(
    async (e: any, attachmentType: AttachmentType) => {
      const file = e.target.files[0]
      if (!file) return
      setLoadingFile({loading: true})
      const invoiceNumber =
        attachmentType === AttachmentType.Payment
          ? `_${invoice.invoiceNumber || ''}`
          : ''
      const fileName = `${
        AttachmentType[attachmentType] + invoiceNumber
      }_${getName(file.name, false, false)}_${Date.now()}.${getFileExtension(
        file.name
      )}`

      const data = await uploadFile([
        {blob: file, filePath: fileName} as UploadFileProps
      ])
      if (!data) {
        setLoadingFile({isFailed: true, errorMessage: 'Error uploading files'})
      } else {
        SaveInvoiceAttchment(file, fileName)
      }
    },
    [uploadFile, SaveInvoiceAttchment, invoice.invoiceNumber]
  )

  const onCloseModal = (
    event: {},
    reason: 'backdropClick' | 'escapeKeyDown'
  ) => {
    if (reason !== 'backdropClick' || !loadingFile.loading) {
      onClose(null)
    }
  }

  return (
    <>
      <Dialog open={open} fullWidth maxWidth={'md'} onClose={onCloseModal}>
        <DialogContent sx={{height: 'calc(40vh)'}}>
          {(loadingFile.loading || loadingFile.errorMessage) && (
            <CuiSnackbarMessage
              isOpenSnackbar={
                loadingFile.loading || loadingFile.errorMessage !== ''
              }
              closeSnackbar={() => setLoadingFile({})}
              message={'Uploading... Please wait' || loadingFile.errorMessage}
              severity={loadingFile.isFailed ? 'error' : 'success'}
              autoHideDuration={4000}
              anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            />
          )}
          {roles?.some(x => x === UserRole[UserRole.Admin]) && (
            <Stack direction={'row'} spacing={2}>
              <label>
                <Input
                  type="file"
                  disabled={loadingFile.loading}
                  onChange={e => {
                    AddFile(e, AttachmentType.Invoice)
                  }}
                />
                <Button
                  disabled={loadingFile.loading}
                  component="span"
                  variant={'contained'}
                >
                  Add Invoice
                </Button>
              </label>
              <label>
                <Input
                  type="file"
                  disabled={loadingFile.loading}
                  onChange={e => {
                    AddFile(e, AttachmentType.Payment)
                  }}
                />
                <Button
                  disabled={loadingFile.loading}
                  component="span"
                  variant={'contained'}
                >
                  Add Payment
                </Button>
              </label>
            </Stack>
          )}
          <List>
            {invoice.attachments?.map((attachment: InvoiceAttachment) => (
              <ListItem key={attachment.id}>
                <ListItemText
                  sx={{width: '70%'}}
                  primary={getName(attachment.file?.path || '', false)}
                />
                <ListItemButton>
                  <CuiProgressButton
                    color="primary"
                    onClick={() => openAttachment(attachment.file)}
                    loading={downloadFile?.path === attachment.file?.path}
                  >
                    <DownloadIcon />
                  </CuiProgressButton>
                </ListItemButton>
                {roles?.some(x => x === UserRole[UserRole.Admin]) && (
                  <ListItemButton
                    onClick={() =>
                      setDeletingFile({attachment: attachment, loading: false})
                    }
                  >
                    <DeleteIcon />
                  </ListItemButton>
                )}
              </ListItem>
            ))}
          </List>
          <CuiConfirmDialog
            title="Delete this file?"
            loading={deletingFile?.loading === true}
            okButtonText="DELETE"
            open={Boolean(deletingFile?.attachment)}
            leaveOpen={true}
            disableBackdropClick={deletingFile?.loading === true}
            close={() => {
              setDeletingFile({})
              setLoadingFile({})
            }}
            onConfirm={() =>
              deletingFile?.attachment &&
              DeleteInvoiceAttchment(deletingFile.attachment)
            }
          >
            Delete file will affect only current invoice
          </CuiConfirmDialog>
        </DialogContent>
      </Dialog>
    </>
  )
}
