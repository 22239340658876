import React, {CSSProperties} from 'react'
import {FixedSizeList} from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import {TableBody} from '@mui/material'
import CuiTableRow from 'src/components/custom/table/CuiTableRow'
import {CuiColumn} from 'src/components/custom/table/CuiTable'
import CuiTableSkeleton from 'src/components/custom/table/CuiTableSkeleton'

interface FixedSizeListRowDataProps {
  rows: any[]
  columns: CuiColumn[]
  onRowClick?: (row: any) => void
  onRowCheck?: (row: any) => void
  onRowEdit?: (row: any) => void
  hover?: boolean
  checkboxColumn?: boolean
  editColumn?: boolean
  clearSelectedRow?: boolean
  keyColumn?: string
  isSelected: (name: string) => boolean
  disableRow?: (row: any) => boolean
  disableActionRow?: (row: any) => boolean
  onRowRender?: (row: any) => void
  collapseRow?: (row: any) => React.ReactNode
}

interface FixedSizeListRowProps {
  index: number
  style: CSSProperties
  data: FixedSizeListRowDataProps
}

const FixedSizeListRow = ({
  index,
  style,
  data: {
    columns,
    rows,
    onRowClick,
    onRowCheck,
    hover,
    checkboxColumn,
    keyColumn,
    clearSelectedRow,
    isSelected,
    disableRow,
    disableActionRow,
    onRowRender
  }
}: FixedSizeListRowProps) => {
  const row = rows[index]

  return (
    <CuiTableRow
      key={index}
      row={row}
      columns={columns}
      style={style}
      onRowClick={onRowClick}
      onRowCheck={onRowCheck}
      hover={hover}
      clearSelectedRow={clearSelectedRow}
      checkboxColumn={checkboxColumn}
      isSelected={isSelected(keyColumn && row[keyColumn])}
      isDisabled={disableRow?.(row)}
      isDisabledAction={disableActionRow?.(row)}
      onRowRender={onRowRender}
      windowingClasses={true}
    />
  )
}

const ROW_HEIGHT = 59

const rowKey = (index: number, data: FixedSizeListRowDataProps) =>
  data.rows[index][data.keyColumn || 'id']

interface CuiTableBodyProps extends FixedSizeListRowDataProps {
  windowing?: boolean
  loading?: boolean
  rowsPerPage: any
  maxHeight?: string | number
}

export default function CuiTableBody({
  windowing,
  loading,
  rowsPerPage,
  maxHeight,
  ...props
}: CuiTableBodyProps) {
  const component = windowing ? 'div' : undefined
  const windowingTBodyProps = windowing && {
    component: component,
    style: {height: `calc(${maxHeight} - ${ROW_HEIGHT}px)`}
  }

  return (
    <TableBody {...windowingTBodyProps}>
      {loading ? (
        <CuiTableSkeleton
          columns={props.columns}
          rowsPerPage={rowsPerPage}
          component={component}
        />
      ) : !windowing ? (
        <>
          {props.rows.map((row, index) => (
            <CuiTableRow
              key={(props.keyColumn && row[props.keyColumn]) || index}
              row={row}
              columns={props.columns}
              onRowClick={props.onRowClick}
              onRowCheck={props.onRowCheck}
              onRowEdit={props.onRowEdit}
              hover={props.hover}
              checkboxColumn={props.checkboxColumn}
              editColumn={props.editColumn}
              clearSelectedRow={props.clearSelectedRow}
              isSelected={props.isSelected(
                props.keyColumn && row[props.keyColumn]
              )}
              isDisabled={props.disableRow?.(row)}
              isDisabledAction={props.disableActionRow?.(row)}
              collapseRow={props.collapseRow}
            />
          ))}
        </>
      ) : (
        <AutoSizer>
          {({height, width}) => {
            return (
              <FixedSizeList
                height={height}
                width={width}
                itemCount={props.rows.length}
                itemSize={ROW_HEIGHT}
                itemKey={rowKey}
                itemData={props}
                style={{overflowX: 'hidden'}}
              >
                {FixedSizeListRow}
              </FixedSizeList>
            )
          }}
        </AutoSizer>
      )}
    </TableBody>
  )
}
